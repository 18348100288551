<template>
    <Header/>
        <Banner name="Partnership"  message="Partner with us." />
        <div class="section section-padding mt-n1">
            <div class="container">
                <!-- Section Title Start -->
                <div class="section-title shape-03 text-center">
                    <h2 class="main-title header-text"><span>Partnership form</span></h2>
                </div>
                <!-- Section Title End -->

                <!-- Blog Wrapper Start -->
                <div class="blog-wrapper">
                    <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-lg-9">
                            <!-- Sponsorship -->
                            <form>
                                <div class="row ">
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Organisation Name</label>
                                        <input type="text" v-model="signupData.name"  placeholder="Organisation Name" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Organisation Email</label>
                                        <input type="email" v-model="signupData.email"  placeholder="Organisation Email" required>
                                    </div>         
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Organisation Address</label>
                                        <input name="org_address" placeholder="Organisation Address" id="org_address" />
                                    </div>                         
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Contact Address</label>
                                        <input type="text" v-model="signupData.org_contact_name"  placeholder="Contact Person's Name" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Contact Email</label>
                                        <input type="email" v-model="signupData.org_contact_email"  placeholder="Contact Person's Email" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Contact Phone Number</label>
                                        <input type="tel" pattern="[0][0-9]{10}" v-model="signupData.org_contact_phone"  placeholder="Contact Phone Number" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label for="" class="text-primary mb-1">Contact Person's Role</label>
                                        <input type="text" v-model="signupData.org_contact_role"  placeholder="Contact Person's Role" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label class="text-primary mb-1">How many students/youths will you like to sponsor?</label>
                                        <input type="number" min="1" v-model="signupData.number_of_students"  placeholder="How many students/youths will you like to sponsor?" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label class="text-primary mb-1">How often will you like to sponsor?</label>
                                        <input type="number" min="1" v-model="signupData.frequency"  placeholder="How often will you like to sponsor?" required>
                                    </div>                                
                                    <div class="single-form col-lg-6">
                                        <label class="text-primary mb-1">Will you like social media publicity?</label>
                                        <select class="nice-select "  name="publicity" @change="selectInput($event)">
                                            <option value="">--Select--</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div class="single-select2 col-lg-6">
                                        <label class="text-primary mb-1">Skills to sponsor</label>
                                        <select class="nice-select " multiple name="trade_of_interest" @change="selectInput($event)">
                                            <option value="">--Select--</option>
                                            <option value="Carpentry">Carpentry & Furniture making</option>
                                            <option value="Plumbing">Plumbing & Pipe Fittings</option>
                                            <option value="Tiling">Tiling</option>
                                            <option value="Masonry">Masonry</option>
                                            <option value="Electrical Installation">Electrical Installation</option>
                                            <option value="Interior Design"> Interior Design</option>
                                            <option value="Welding and fabrication"> Welding and fabrication</option>
                                            <option value="CCTV Installation"> CCTV Installation </option>
                                            <option value="Solar Design and Installation"> Solar Design and Installation</option>
                                            <option value="Painting"> Painting </option>
                                            <option value="Building Construction & Supervision"> Building Construction & Supervision</option>
                                        </select>
                                    </div> 
                                    <div class="single-form col-lg-6">
                                        <label class="text-primary mb-1">When will you like to start the sponsorship?</label>
                                        <input type="date" v-model="signupData.start_date"  required>
                                    </div>    
                                    <div class="single-form">
                                        <button 
                                            type="button" 
                                            class="btn btn-primary btn-hover-dark w-100"
                                            @click="signup"
                                        >
                                            <span v-if="loading">Please wait...</span>
                                            <span v-else>Submit</span> 
                                        </button>
                                    </div>                                                                                                                                                          
                                </div>
                            </form> 
                        <!-- /Sponsorship -->
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import SingleEvent from '@/components/Events/SingleEvent.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default{
    name:"EventShow",
    components:{
        Header,Banner,Footer,SingleEvent
    },
    data() {
        return {
            details: {
                title: '',
                body: ''
            },
            signupData: {},
            loading: false, 
            localGovernments: []
        }
    },
    methods: {
        selectInput(event) {
            const {name, value} = event.target
            this.signupData[name] = value
        },
     setDateField(event) {
        event.target.type = 'date'
     },
     signup() {
         this.loading = true
         this.signupData.event_id = this.details.id
         axios.post('https://api.bhubcollege.com/api/v1/events/registration/create', this.signupData)
         .then(response => {
            if(response.data.status) {
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    text: response.data.message
                })
                .then(r => {
                    this.signupData = {}
                })
            }
         })
         .catch(err => {
            if(err.response && err.response.data) {
                if(err.response.status == 422 || err.response.status == 404) {
                    Swal.fire({
                        title: 'Error',
                        text: err.response.data.message,
                        icon: 'error'
                    })
                } 
            } 
         })
         .finally(() => this.loading = false)
     },
    }
}
</script>